

























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { register, getSMSToken, applyLoan, uploadFile } from '@/scripts/api/login';
import { errHandle, showMessage } from '@/scripts/utils/library';
import { mapGetters, mapActions } from 'vuex';
import DisclaimerTerms from '@/components/DisclaimerTerms.vue';
import UploadLoanFile from '@/components/UploadLoanFile.vue';
import ApplicantInfo from '@/components/ApplicantInfo.vue';
import ViewTitle from '@/components/ViewTitle.vue';

const TIME_COUNT = 60;

export default Vue.extend({
    components: {
        DisclaimerTerms,
        UploadLoanFile,
        ApplicantInfo,
        ViewTitle,
    },
    data() {
        return {
            formData: {
                loan_amt: 10000,
                loan_period: 12,
                property_addr: '',
                remark: '',
                property_usage: 'OWN',
                repay_method: 'INT',
                property_ownership: 'WHOLE',
                property_other_ownership: 2,
                curr_debt1: 0,
                curr_debt2: 0,
                curr_other_debt: 0,
                property_info: 'PRIV',
                email: '',
                mobile: '',
                sms_token: '',
                client_files: [],
                acknowledge_store_applicant_data: false,
                acknowledge_disclaimer: false,
                gender: 'Mr',
                full_name: '',
                from_channel: '',
            },
            formRules: {
                loan_amt: [
                    { required: true, message: '請輸入貸款金額', trigger: ['blur'] },
                ],
                property_addr: [
                    { required: true, message: '請輸入物業地址', trigger: ['blur'] },
                ],
                mobile: [
                    { required: true, len: 8, pattern: /^[0-9]+$/, message: '請輸入8位數字香港手提電話號碼', trigger: ['blur'] },
                ],
                sms_token: [
                    { required: true, message: '請輸入SMS 短訊驗證碼', trigger: ['blur'] },
                ],
                acknowledge_store_applicant_data: [
                    { message: '請確認「本人同意」免責聲明', trigger: ['blur'],
                      validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                          callback();
                        } else {
                          callback(new Error());
                        }
                      },
                    },
                ],
                acknowledge_disclaimer: [
                    { message: '請確認「本人同意」免責聲明', trigger: ['blur'],
                      validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                          callback();
                        } else {
                          callback(new Error());
                        }
                      },
                    },
                ],
            },
            fileList: [],
            submitting: false,
            gettingCode: false,
            disclaimerVisible: false,
            count: TIME_COUNT,
            timer: null as any,
            activePage: 0,
            pageApplicant: 0,
            pageLoan: 0,
            pageSubmit: 0,
        };
    },
    computed: {
        dialogStyle() {
            return 'height: ' + (screen.height * 0.5) + 'px';
        },
        ...mapGetters([
            'isLogined',
        ]),
    },
    mounted() {
      this.resetFields();
    },
    methods: {
        pageTitleApplicant() {
            return (this.pageApplicant + 1).toString() + ') 填寫申請人資料';
        },
        pageTitleLoan() {
            return (this.pageLoan + 1).toString() + ') 填寫貸款資料';
        },
        pageTitleSubmit() {
            return (this.pageSubmit + 1).toString() + ') 確認貸款申請';
        },
        resetFields() {
            // console.debug('calling resetFields');
            (this.$refs.form as any).resetFields();
            (this.$refs.fileUpload as any).clearFiles();
            // console.debug('mobile=' + this.$store.state.mobile + ',logined=' + this.$store.isLogined);
            this.count = 0;
            // if (this.$refs.isLogined) {
            // cannot use isLogined, not initialized yet
            if (this.$store.state.mobile !== '') {
                this.formData.mobile = this.$store.state.mobile;
                this.formData.acknowledge_store_applicant_data = true;
                this.pageApplicant = -1;
                this.pageLoan = 0;
                this.pageSubmit = 1;
            } else {
                this.pageApplicant = 0;
                this.pageLoan = 1;
                this.pageSubmit = 2;
            }
            this.activePage = 0;
            // console.debug('calling resetFields end');
        },
        getClientFiles() {
          return (this.fileList as any).map((file: any) => {
            const item = { uid: file.uid, filename: file.name };
            return item;
          });
        },
        beforeUpload(file: any) {
          console.debug('beforeUpload');
          const formData = new FormData();
          formData.append('file', file);
          return uploadFile(formData, file.uid);
        },
        handleChange(file: any, fileList: any) {
          console.debug('handleChange');
          this.fileList = fileList;
        },
        handleRemove(file: any, fileList: any) {
          console.debug('handleRemove');
        },
        handleSuccess(response: any, file: any, fileList: any) {
          console.debug('handleSuccess');
          this.$message.info('成功上傳文件');
          // this.fileList = fileList;
        },
        handleExceed(files: any, fileList: any) {
          console.debug('handleExceed');
          this.$message.warning('最多上傳 ${files.length} 個文件');
        },
        handleFile(param: any) {
          console.debug('handleFile');
          console.dir(param);
          // handle file dummy
        },
        getCode() {
            if (!this.timer) {
                getSMSToken(this.formData.mobile, '申請貸款', 'A-').then((response) => {
                    this.count = TIME_COUNT;
                    this.gettingCode = true;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.gettingCode = false;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }).catch((error) => {
                    console.debug('get sms token submit error:' + error);
                }).finally(() => {
                    // do nothing
                });

                // (this.$refs.form as any).validateField('mobile', (validateError: string) => {
                //     // console.debug('valid field dump');
                //     // console.dir(valid);
                //     if (validateError) {
                //         errHandle(this, validateError);
                //     } else {
                //         getSMSToken(this.formData.mobile, '申請貸款', 'A-').then((response) => {
                //             console.debug('get sms token success');
                //             this.count = TIME_COUNT;
                //             this.gettingCode = true;
                //             this.timer = setInterval(() => {
                //                 if (this.count > 0 && this.count <= TIME_COUNT) {
                //                     this.count--;
                //                 } else {
                //                     this.gettingCode = false;
                //                     clearInterval(this.timer);
                //                     this.timer = null;
                //                 }
                //             }, 1000);
                //         }).catch((error) => {
                //             console.debug('get sms token submit error:' + error);
                //         }).finally(() => {
                //             // do nothing
                //         });
                //     }
                // });
            }
        },
        onPrev() {
            this.activePage--;
            if (this.activePage < 0) {
                this.activePage = 0;
            }
            window.scrollTo(0, 0);
        },
        onNext() {
            (this.$refs.form as any).validate((valid: boolean, failedFields: object) => {
                if (valid) {
                    // valid ok
                    this.submitting = true;
                    register(this.formData, '/register-new').then((response) => {
                        console.debug('reister-new success');
                        this.activePage++;
                        window.scrollTo(0, 0);
                    }).catch((error) => {
                        console.debug('submit error:' + error);
                        errHandle(this, error);
                    }).finally(() => {
                        console.debug('submit finally');
                        this.submitting = false;
                    });
                } else {
                  errHandle(this, failedFields);
                  return false;
                }
            });
        },
        onSubmit() {
            (this.$refs.form as any).validate((valid: boolean, failedFields: object) => {
                if (valid) {
                    // valid ok
                    console.debug('valid ok');
                    this.submitting = true;
                    this.formData.client_files = this.getClientFiles();
                    applyLoan(this.formData).then((response) => {
                        console.debug('submit success');
                        showMessage(this, '貸款申請已成功傳送，我們會盡快處理你的申請。你可隨時登錄並查詢你的申請處理狀況。');
                        // this.resetFields();
                        const payload = {loginSess: response.data.login_session, mobile: this.formData.mobile};
                        this.$store.dispatch('logined', payload);
                        this.$router.push('/loan-app');
                    }).catch((error) => {
                        console.debug('submit error:' + error);
                        errHandle(this, error);
                    }).finally(() => {
                        console.debug('submit finally');
                        this.submitting = false;
                    });
                } else {
                  errHandle(this, failedFields);
                  return false;
                }
            });
        },
    },
    // components: {
    //     f7List,
    //     f7ListInput,
    // },
});
