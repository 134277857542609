























import { Component, Prop, Vue } from 'vue-property-decorator';
import { uploadFile } from '@/scripts/api/login';
import * as library from '@/scripts/utils/library';

export default Vue.extend({
    data() {
      return {
        isUploading: false,
      };
    },
    props: {
      value: Array,
      showFileList: {
        type: Boolean,
        default: true,
      },
      accept: {
        type: String,
        // default: '.png,.jpg,.jpeg,.gif,.tif,.tiff,.bmp,.pdf',
        default: 'image/png,image/jpeg,image/gif,image/tiff,image/bmp,application/pdf',
      },
    },
    methods: {
        beforeUpload(file: any) {
          console.debug('beforeUpload ' + file.name + ' ' + file.type);
          const isTypeOk = this.$props.accept.includes(file.type);
          // const isTypeOk = true;
          const isSizeOk = file.size / 1024 / 1024 < 5;

          if (!isTypeOk) {
            library.errHandle(this, '上傳文件格式不正確');
            return false;
          }
          if (!isSizeOk) {
            library.errHandle(this, '上傳文件大小不能超 5MB');
            return false;
          }

          const formData = new FormData();
          formData.append('file', file);
          this.$data.isUploading = true;
          return uploadFile(formData, file.uid);
        },
        handleChange(file: any, fileList: any) {
          console.debug('handleChange');
          // this.fileList = fileList;
          this.$emit('input', fileList);
        },
        handleRemove(file: any, fileList: any) {
          console.debug('handleRemove');
        },
        handleError(error: any, file: any, fileList: any) {
          console.debug('handleError');
          this.$message.info('上傳文件失敗');
          // this.fileList = fileList;
          this.$data.isUploading = false;
        },
        handleSuccess(response: any, file: any, fileList: any) {
          console.debug('handleSuccess');
          this.$message.info('成功上傳文件');
          // this.fileList = fileList;
          this.$data.isUploading = false;
        },
        handleExceed(files: any, fileList: any) {
          console.debug('handleExceed');
          this.$message.warning('最多上傳 ${files.length} 個文件');
        },
        handleFile(param: any) {
          console.debug('handleFile');
          console.dir(param);
          // handle file dummy
          this.$emit('after-upload', param.file);
          this.$data.isUploading = false;
        },
        clearFiles() {
          (this.$refs.fileUpload as any).clearFiles();
        },
    },
});
